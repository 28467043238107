.App {
  min-height: 100vh;
  min-width: 100vw;
  color: white;
  /* To disable margin stack */
  padding: 1px 0.5rem;
  overflow-x: hidden;
}

.main-content {
  position: relative;
  margin: auto;
  max-width: 60em;
}
