.home-page a {
  text-decoration: none;
}

.home-page > h1 {
  flex-shrink: 1;
  margin-bottom: 0.8rem;
}

.home-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.home-page > .main-content {
  flex-grow: 1;
}

.home-page > footer {
  padding: 1rem;
  color: gray;
}

.home-page .footer-links > a {
  padding: 0.5rem;
}

.game-mode-card {
  height: 100%;
}

.home-page-title {
  position: relative;
  font-family: monospace;
}

.home-page-title span {
  position: relative;
}

.home-logo {
  height: 2rem;
  width: 2rem;
  vertical-align: middle;
  margin-right: 0.5rem;
}

.home-page-subtitle {
  font-family: monospace;
  display: block;
  color: gray;
  font-size: 1.2rem;
}
