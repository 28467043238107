.classic-page {
  text-align: center;
  margin-top: 1rem;
}

.classic-page .event-stream {
  color: lightgray;
  margin: auto;
  overflow: hidden;
  position: absolute;
  left: 100%;
  top: 1.5rem;
  text-align: left;
  padding: 0 1rem;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  height: 75%;
}

.classic-page .event-stream::after {
  position: absolute;
  bottom: 0;
  content: "";
  height: 90%;
  width: calc(100% - 1rem);
  background: linear-gradient(0, #121212, transparent);
}

.classic-page .success-overlay {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(50, 55, 50, 0.8);
  position: absolute;
  padding: 0 2rem;
  font-size: 1.1rem;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 6px -2px lime inset;
}

.classic-page .success-author-origin {
  text-align: right;
}

.classic-page .success-overlay h3 {
  font-size: 1.2rem;
  text-align: center;
  margin: 0;
  color: limegreen;
}
.classic-page .success-overlay p {
  margin-top: 0.5rem;
}

.classic-page .puzzle-header {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 0.25rem;
  color: lightgray;
}

.success-button-group {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
}

#puzzle-self-link {
  opacity: 0.01;
  background-color: transparent;
}
