.cipher-text-display {
  padding: 1rem;
  word-break: break-word;
  text-align: justify;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  font-family: monospace;
  padding-bottom: 1.5rem;
}

.cipher-text-display .pressed {
  font-weight: bold;
}

.cipher-text-display .locked {
  background-color: #000;
  color: #aaa;
}

/* Make the puzzle overlay big enough to have space for minimal success overlay */
.puzzle .cipher-text-display {
  min-height: 12rem;
}

.puzzle-letter {
  animation: letter_change ease-out 1s;
}

.puzzle-buttons {
  bottom: 0;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.8);
  transform: translateY(100%);
  transition: transform ease-out 0.2s;
}

.puzzle-buttons:before {
  position: absolute;
  bottom: 100%;
  content: "⌃";
  line-height: 1rem;
  font-size: 2rem;
  font-weight: bold;
  color: #3a3a3a;
  left: 10%;
  border-radius: 2px;
  width: 80%;
  opacity: 1;
  transition: opacity ease-out 0.2s;
}

.puzzle-buttons-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.puzzle.active:hover .puzzle-buttons {
  transform: translateY(0%);
}

.puzzle-buttons-hit-area:hover .puzzle-buttons:before {
  opacity: 0;
}

.puzzle-buttons > * {
  margin: 0 0.25rem;
}

@keyframes letter_change {
  from {
    color: #0f0;
  }
  to {
    color: inherit;
  }
}

.puzzle-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.puzzle-solved-overlay {
  animation: fade_in 1s linear;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.puzzle-overlayable {
  position: relative;
  overflow: hidden;
}

@keyframes fade_in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade_out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.blurred {
  animation: blur 1s linear;
  filter: blur(5px);
}

@keyframes blurout {
  from {
    filter: blur(0);
  }
  to {
    filter: blur(5px);
  }
}

.puzzle .user-input-info-bar {
  padding-top: 0.2rem;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  color: #666;
}

.puzzle .puzzle-lock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #222;
  font-size: 1.2rem;
}

.key-indicator {
  display: inline-block;
  padding: 0.15rem;
  border-radius: 0.2rem;
  border: 1px solid black;
  width: 2rem;
  height: 2rem;
  font-family: monospace;
}

.key-indicator.pressed {
  border-color: lightgray;
  font-weight: bold;
}

.puzzle-lock.released .key-indicator {
  animation: letter_change ease-out 1s;
}

.puzzle-lock.released {
  animation: fade_in 1.5s ease-in-out reverse;
  opacity: 0;
}
