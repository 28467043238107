.page-header {
  text-align: center;
}

.page-header .back-button {
  position: absolute;
  left: 0;
  padding: 0 1.5rem;
  line-height: 36px;
  font-size: 1rem;
  color: white;
  text-decoration: none;
}
